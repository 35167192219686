import HeroSlide from './HeroSlide';

const HeroSection = () => {
  return (
    <section id='hero' className='hero'>
      <div className='info d-flex align-items-center'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-8 text-center'>
              <h2 data-aos='fade-down'>
                <span>«Արթուր Աբելյան Աշոտի»</span>
                <br />
                ԱՁ
              </h2>
              <p data-aos='fade-up'>
                Բնակելի, հասարակական և արտադրական կառույցների ճարտարագետ,
                <br />
                տեխնիկական հսկիչ, 1-ին կարգ
              </p>
              <a
                data-aos='fade-up'
                data-aos-delay='200'
                href='#descriptions'
                className='btn-get-started'>
                Սկսել
              </a>
            </div>
          </div>
        </div>
      </div>

      <HeroSlide />
    </section>
  );
};

export default HeroSection;
