import imgHomeCarousel1 from 'assets/img/illustrations/home/hero-carousel/hero-carousel-1.jpg';
import imgHomeCarousel2 from 'assets/img/illustrations/home/hero-carousel/hero-carousel-2.jpg';
import imgHomeCarousel3 from 'assets/img/illustrations/home/hero-carousel/hero-carousel-3.jpg';
import imgHomeCarousel4 from 'assets/img/illustrations/home/hero-carousel/hero-carousel-4.jpg';
import imgHomeCarousel5 from 'assets/img/illustrations/home/hero-carousel/hero-carousel-5.jpg';

const images = [
  imgHomeCarousel1,
  imgHomeCarousel2,
  imgHomeCarousel3,
  imgHomeCarousel4,
  imgHomeCarousel5,
];

const HeroSlide = () => {
  return (
    <div
      id='hero-carousel'
      className='carousel slide'
      data-bs-ride='carousel'
      data-bs-interval='5000'>
      {images.map((image, index) => (
        <div
          key={index}
          className={`carousel-item ${index === 0 ? 'active' : ''}`}
          style={{ backgroundImage: `url(${image})` }}
        />
      ))}

      <a className='carousel-control-prev' href='#hero-carousel' role='button' data-bs-slide='prev'>
        <span className='carousel-control-prev-icon bi bi-chevron-left' aria-hidden='true'></span>
      </a>

      <a className='carousel-control-next' href='#hero-carousel' role='button' data-bs-slide='next'>
        <span className='carousel-control-next-icon bi bi-chevron-right' aria-hidden='true'></span>
      </a>
    </div>
  );
};

export default HeroSlide;
