import React from 'react';

const DescriptionsArea = () => {
  return (
    <section id='descriptions'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-6 mx-auto'>
            <p data-aos='fade-down' className='text-center mb-5'>
              «Արթուր Աբելյան Աշոտի» ԱՁ-ն մասնագիտացած է շինարարության որակի տեխնիկական հսկողության
              ոլորտում:
            </p>
            <div data-aos='fade-up' className='section-header pb-5'>
              <h4>Մենք իրականացնում ենք՝</h4>
            </div>
            <ul>
              <li data-aos='fade-up' className='mb-3'>
                մասնակցություն շենքերի, շինությունների առանցքների նշահարմանը, գեոդեզիական սպասարկման
                և չափագրությունների ապահովում
              </li>
              <li data-aos='fade-up' className='mb-3'>
                շինմոնտաժային աշխատանքների որակի ու տեխնոլոգիական հերթականության պարբերաբար
                ստուգումներ
              </li>
              <li data-aos='fade-up' className='mb-3'>
                նախագծին և շինարարական նորմաներին ու կանոններին շին. աշխատանքների համապատասխանության
                ստուգումներ
              </li>
              <li data-aos='fade-up' className='mb-3'>
                օգտագործվող նյութերի, շինվածքների, կոնստրուկցիաների և ինժեներական արքավորումների
                որակը հաստատող սերտիֆիկատների, տեխնիկական անձնագրերի, լաբորատոր փորձարկումների և
                անալիզների արդյունքների գոյության, ինչպես նաև՝ նախագծին ու որակի պահանջներին դրանց
                համապատասխանության ստուգումներ
              </li>
              <li data-aos='fade-up' className='mb-3'>
                հետևում ենք նախագծային և շինարարական կազմակերպությունների կողմից պայմանագրային
                պարտականությունների կատարմանը
              </li>
              <li data-aos='fade-up'>տեխ. հսկողության հրահանգին համապատասխան այլ գործառույթներ:</li>
            </ul>
            <p data-aos='fade-up'>
              Մենք պատրաստ ենք Ձեզ հետ համագործակցել՝ վստահելի և մասնագիտական մոտեցմամբ:
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DescriptionsArea;
