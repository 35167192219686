import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

const navItems = [
  {
    path: '/',
    label: 'Գլխավոր',
  },
  {
    path: '/projects',
    label: 'Նախագծեր',
  },
  {
    path: '/contact',
    label: 'Կապ',
  },
];

const Navbar = () => {
  const [open, setOpen] = useState(false);

  const onToggle = () => setOpen(!open);

  useEffect(() => {
    document.body.classList[open ? 'add' : 'remove']('mobile-nav-active');
    return () => document.body.classList.remove('mobile-nav-active');
  }, [open]);
  return (
    <>
      {open ? (
        <i
          className='mobile-nav-toggle mobile-nav-hide bi bi-x'
          onClick={onToggle}
          role='presentation'
        />
      ) : (
        <i
          className='mobile-nav-toggle mobile-nav-show bi bi-list'
          onClick={onToggle}
          role='presentation'
        />
      )}

      <nav id='navbar' className='navbar'>
        <ul>
          {navItems.map((item, index) => (
            <li key={index}>
              <NavLink to={item.path} onClick={onToggle}>
                {item.label}
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
    </>
  );
};

export default Navbar;
