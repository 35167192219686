import barbyusi_66 from './barbyusi_66.js';
import khanjyan_9_2 from './khanjyan_9-2.js';
import leningradyan_29_17 from './leningradyan_29-17.js';
import sosei_52_6_ev_52_8 from './sosei_52-6_ev_52-8.js';
import tsovakal_Isakov_12_11 from './tsovakal_Isakov_12-11.js';
import verin_antarayin_36_11 from './verin_antarayin_36-11.js';
import zeytun_10_str_15 from './zeytun_10_str_15.js';

export default [
  barbyusi_66,
  khanjyan_9_2,
  leningradyan_29_17,
  sosei_52_6_ev_52_8,
  tsovakal_Isakov_12_11,
  verin_antarayin_36_11,
  zeytun_10_str_15,
];
