import { createReducer } from '../../../utils/reduxHelpers';
import { AUTH } from '../../actionTypes';

const initialState = null;

const handlers = {
  [AUTH.SET_STATE]: (_, action) => action.payload,
};

export default createReducer(initialState, handlers);
