import Footer from 'shared/components/layout/Footer';
import Header from 'shared/components/layout/Header';
import useScrollTopOnPageChange from 'shared/hooks/useScrollTopOnPageChange';

const MainLayout = ({ children }) => {
  useScrollTopOnPageChange();

  return (
    <>
      <Header />
      <main id='main'>{children}</main>
      <Footer />
    </>
  );
};

export default MainLayout;
