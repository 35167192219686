import { Link } from 'react-router-dom';
import data from 'app/routes/Projects/consts/data';

const Projects = ({ className }) => {
  return (
    <section id='projects' className={`projects ${className}`}>
      <div className='section-header'>
        <h2>Մեր Նախագծերը</h2>
      </div>
      <div className='container' data-aos='fade-up'>
        <div
          className='row justify-content-center gy-4 portfolio-container'
          data-aos='fade-up'
          data-aos-delay='200'>
          {data.map((project) => (
            <div className='col-lg-4 col-md-6 d-flex flex-column' key={project.id}>
              <div className='portfolio-content flex-fill'>
                <img src={project.images[0]} className='img-fluid' alt={project.title} />
                <Link to={`/projects/${project.id}`} className='portfolio-info'>
                  <h4>{project.category}</h4>
                  <p>{project.title}</p>
                  <span className='preview-link'>
                    <i className='bi bi-zoom-in'></i>
                  </span>
                  <span title='More Details' className='details-link'>
                    <i className='bi bi-link-45deg'></i>
                  </span>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Projects;
