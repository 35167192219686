import { Link } from 'react-router-dom';
import Navbar from './Navbar';

const Header = () => {
  return (
    <header id='header' className='header d-flex align-items-center'>
      <div className='container-fluid container-xl d-flex align-items-center justify-content-between'>
        <Link to='/' className='logo d-flex align-items-center'>
          <h1>
            ԱԱԱ<span>.</span>
          </h1>
        </Link>
        <Navbar />
      </div>
    </header>
  );
};

export default Header;
