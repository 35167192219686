import img1 from 'assets/img/projects/tsovakal_Isakov_12-11/1.jpg';
import img2 from 'assets/img/projects/tsovakal_Isakov_12-11/2.jpg';
import img3 from 'assets/img/projects/tsovakal_Isakov_12-11/3.jpg';
import img4 from 'assets/img/projects/tsovakal_Isakov_12-11/4.jpg';

export default {
  id: 'tsovakal_Isakov_12-11',
  title: 'Լենինգրադյան փողոց 29/17',
  category: 'Բնակելի',
  images: [img1, img2, img3, img4],
};
