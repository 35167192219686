import { createReducer } from '../../../utils/reduxHelpers';
import { AUTH } from '../../actionTypes';

const initialState = null;

const handlers = {
  [AUTH.LOGIN]: (_, action) => action.payload,
  [AUTH.LOGOUT]: () => initialState,
};

export default createReducer(initialState, handlers);
