import img1 from 'assets/img/projects/verin_antarayin_36-11/1.jpg';
import img2 from 'assets/img/projects/verin_antarayin_36-11/2.jpg';
import img3 from 'assets/img/projects/verin_antarayin_36-11/3.jpg';
import img4 from 'assets/img/projects/verin_antarayin_36-11/4.jpg';

export default {
  id: 'verin_antarayin_36-11',
  title: 'Վերին Անտարային փողոց 36/11',
  category: 'Բնակելի',
  images: [img1, img2, img3, img4],
};
