import img1 from 'assets/img/projects/barbyusi_66/1.jpg';
import img2 from 'assets/img/projects/barbyusi_66/2.jpg';
import img3 from 'assets/img/projects/barbyusi_66/3.jpg';

export default {
  id: 'barbyusi_66',
  title: 'Բարբյուսի փողոց 66',
  category: 'Բնակելի',
  images: [img1, img2, img3],
};
