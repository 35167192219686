// Load vendors
import { combineReducers } from 'redux';

// Load reducers
import isAuthenticated from './reducers/auth/isAuthenticated';
import profile from './reducers/auth/profile';

const rootReducer = combineReducers({ isAuthenticated, profile });

export default rootReducer;
