import img1 from 'assets/img/projects/leningradyan_29-17/1.jpg';
import img2 from 'assets/img/projects/leningradyan_29-17/2.jpg';
import img3 from 'assets/img/projects/leningradyan_29-17/3.jpg';
import img4 from 'assets/img/projects/leningradyan_29-17/4.jpg';
import img5 from 'assets/img/projects/leningradyan_29-17/5.jpg';

export default {
  id: 'leningradyan_29-17',
  title: 'Լենինգրադյան փողոց 29/17',
  category: 'Բնակելի',
  images: [img1, img2, img3, img4, img5],
};
