import { Link } from 'react-router-dom';
import imgBreadcrumb from 'assets/img/illustrations/vendor/breadcrumbs-bg.jpg';

const Breadcrumbs = ({ root = { path: '/', title: 'Գլխավոր' }, title }) => {
  return (
    <div
      className='breadcrumbs d-flex align-items-center'
      style={{ backgroundImage: `url(${imgBreadcrumb})` }}>
      <div
        className='container position-relative d-flex flex-column align-items-center'
        data-aos='fade'>
        <h2>{title}</h2>
        <ol>
          <li>
            <Link to={root.path}>{root.title}</Link>
          </li>
          <li>{title}</li>
        </ol>
      </div>
    </div>
  );
};

export default Breadcrumbs;
