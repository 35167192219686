import img1 from 'assets/img/projects/zeytun_10_str_15/1.jpg';
import img2 from 'assets/img/projects/zeytun_10_str_15/2.jpg';
import img3 from 'assets/img/projects/zeytun_10_str_15/3.jpg';
import img4 from 'assets/img/projects/zeytun_10_str_15/4.jpg';

export default {
  id: 'zeytun_10_str_15',
  title: 'Քանաքեռ-Զեյթուն 10 փողոց, 15',
  category: 'Բնակելի',
  images: [img1, img2, img3, img4],
};
