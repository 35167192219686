const DetailsSlider = ({ images }) => {
  return (
    <section className='detail-carousel'>
      <div
        id='detail-carousel'
        className='carousel slide'
        data-bs-ride='carousel'
        data-bs-interval='5000'>
        {images.map((image, index) => (
          <div
            key={index}
            className={`carousel-item ${index === 0 ? 'active' : ''}`}
            style={{ backgroundImage: `url(${image})` }}
          />
        ))}

        <a
          className='carousel-control-prev'
          href='#detail-carousel'
          role='button'
          data-bs-slide='prev'>
          <span className='carousel-control-prev-icon bi bi-chevron-left' aria-hidden='true'></span>
        </a>

        <a
          className='carousel-control-next'
          href='#detail-carousel'
          role='button'
          data-bs-slide='next'>
          <span
            className='carousel-control-next-icon bi bi-chevron-right'
            aria-hidden='true'></span>
        </a>
      </div>
    </section>
  );
};

export default DetailsSlider;
