import img1 from 'assets/img/projects/khanjyan_9-2/1.jpg';
import img2 from 'assets/img/projects/khanjyan_9-2/2.jpg';
import img3 from 'assets/img/projects/khanjyan_9-2/3.jpg';
import img4 from 'assets/img/projects/khanjyan_9-2/4.jpg';

export default {
  id: 'khanjyan_9-2',
  title: 'Խանջյան փողոց 9/2',
  category: 'Բնակելի',
  images: [img1, img2, img3, img4],
};
