import HeroSection from './components/HeroSection';
import DescriptionsArea from './components/DescriptionsArea';
import ProjectsList from 'app/routes/Projects/components/ProjectsList';

const Home = () => {
  return (
    <>
      <HeroSection />
      <DescriptionsArea />
      <ProjectsList className='section-bg' />
    </>
  );
};

export default Home;
