import Breadcrumbs from 'shared/components/layout/Breadcrumbs';
import ProjectsList from './components/ProjectsList';

const Projects = () => {
  return (
    <>
      <Breadcrumbs title='Նախագծեր' />
      <ProjectsList />
    </>
  );
};

export default Projects;
