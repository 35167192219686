const Footer = () => {
  return (
    <footer id='footer' className='footer'>
      <div className='footer-content position-relative'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6'>
              <div className='footer-info'>
                <h3>«Արթուր Աբելյան Աշոտի» ԱՁ</h3>
                <address className='mb-3'>
                  <p>
                    <strong>Հեռ.:</strong> <a href='tel:+37494122477'>+374 94 122 477</a>
                  </p>
                  <p>
                    <strong>Email:</strong>{' '}
                    <a href='mailto:abelyan.arthur@rambler.ru'>abelyan.arthur@rambler.ru</a>
                  </p>
                </address>
                <div className='social-links d-flex'>
                  <a
                    href='https://www.facebook.com/artur.abelyan.77'
                    className='d-flex align-items-center justify-content-center'
                    target='blank'
                    aria-label='Facebook'>
                    <i className='fab fa-facebook'></i>
                  </a>
                  <a
                    href='viber://forward?text=Your%20Message%20Here'
                    className='d-flex align-items-center justify-content-center'
                    aria-label='Viber'>
                    <i className='fab fa-viber'></i>
                  </a>
                  <a
                    href='whatsapp://send?text=Your%20Message%20Here'
                    className='d-flex align-items-center justify-content-center'
                    aria-label='Whatsapp'>
                    <i className='fab fa-whatsapp'></i>
                  </a>
                </div>
              </div>
            </div>

            {/* <nav className='col-lg-2 col-md-3 footer-links'>
              <h4>Useful Links</h4>
              <ul>
                <li>
                  <a href='#'>Home</a>
                </li>
                <li>
                  <a href='#'>About us</a>
                </li>
                <li>
                  <a href='#'>Services</a>
                </li>
                <li>
                  <a href='#'>Terms of service</a>
                </li>
                <li>
                  <a href='#'>Privacy policy</a>
                </li>
              </ul>
            </nav>

            <nav className='col-lg-2 col-md-3 footer-links'>
              <h4>Our Services</h4>
              <ul>
                <li>
                  <a href='#'>Web Design</a>
                </li>
                <li>
                  <a href='#'>Web Development</a>
                </li>
                <li>
                  <a href='#'>Product Management</a>
                </li>
                <li>
                  <a href='#'>Marketing</a>
                </li>
                <li>
                  <a href='#'>Graphic Design</a>
                </li>
              </ul>
            </nav>

            <nav className='col-lg-2 col-md-3 footer-links'>
              <h4>Hic solutasetp</h4>
              <ul>
                <li>
                  <a href='#'>Molestiae accusamus iure</a>
                </li>
                <li>
                  <a href='#'>Excepturi dignissimos</a>
                </li>
                <li>
                  <a href='#'>Suscipit distinctio</a>
                </li>
                <li>
                  <a href='#'>Dilecta</a>
                </li>
                <li>
                  <a href='#'>Sit quas consectetur</a>
                </li>
              </ul>
            </nav>

            <nav className='col-lg-2 col-md-3 footer-links'>
              <h4>Nobis illum</h4>
              <ul>
                <li>
                  <a href='#'>Ipsam</a>
                </li>
                <li>
                  <a href='#'>Laudantium dolorum</a>
                </li>
                <li>
                  <a href='#'>Dinera</a>
                </li>
                <li>
                  <a href='#'>Trodelas</a>
                </li>
                <li>
                  <a href='#'>Flexo</a>
                </li>
              </ul>
            </nav> */}
          </div>
        </div>
      </div>

      <div className='footer-legal text-center position-relative'>
        <div className='container'>
          <div className='copyright pt-4'>
            &copy; {new Date().getFullYear()} {/* <strong> */}
            <span>Abelyan.am</span>
            {/* </strong> */}. Բոլոր իրավունքները պաշտպանված են
          </div>
          {/* <div className='credits'>
            Designed by{' '}
            <a href='https://bootstrapmade.com/' rel='noopener noreferrer' target='_blank'>
              BootstrapMade
            </a>
            Distributed by{' '}
            <a href='https://themewagon.com' rel='noopener noreferrer' target='_blank'>
              ThemeWagon
            </a>
          </div> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
