import Breadcrumbs from 'shared/components/layout/Breadcrumbs';
import ContactSection from './ContactSection';

const Contact = () => {
  return (
    <>
      <Breadcrumbs title='Կապ' />
      <ContactSection />
    </>
  );
};

export default Contact;
