import DetailsSlider from './DetailsSlider';

const DetailsSection = ({ project = {} }) => {
  return (
    <section id='project-details' className='project-details'>
      <div className='container' data-aos='fade-up' data-aos-delay='100'>
        <DetailsSlider images={project.images} />

        {/* <div className='row justify-content-between gy-4 mt-4'>
          <div className='col-lg-3'>
            <div className='portfolio-info'>
              <h3>Project information</h3>
              <ul>
                <li>
                  <strong>Category</strong> <span>Web design</span>
                </li>
                <li>
                  <strong>Client</strong> <span>ASU Company</span>
                </li>
                <li>
                  <strong>Project date</strong> <span>01 March, 2020</span>
                </li>
                <li>
                  <strong>Project URL</strong> <a href='#'>www.example.com</a>
                </li>
                <li>
                  <a href='#' className='btn-visit align-self-start'>
                    Visit Website
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default DetailsSection;
