import { useParams } from 'react-router-dom';

import Breadcrumbs from 'shared/components/layout/Breadcrumbs';
import DetailsSection from './components/DetailsSection';

import data from 'app/routes/Projects/consts/data';

const ProjectView = () => {
  const { id } = useParams();
  const project = data.find((project) => project.id === id);

  return (
    <>
      <Breadcrumbs root={{ path: '/projects', title: 'Նախագծեր' }} title={project.title} />
      <DetailsSection project={project} />
    </>
  );
};

export default ProjectView;
