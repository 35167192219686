const ContactSection = () => {
  return (
    <section id='contact' className='contact'>
      <div className='container' data-aos='fade-up' data-aos-delay='100'>
        <div className='row gy-4'>
          <div className='col-md-6'>
            <div className='info-item d-flex flex-column justify-content-center align-items-center'>
              <i className='bi bi-envelope'></i>
              <h3>Ուղարկել նամակ</h3>
              <p>
                <a href='mailto:abelyan.arthur@rambler.ru'>abelyan.arthur@rambler.ru</a>
              </p>
            </div>
          </div>

          <div className='col-md-6'>
            <div className='info-item  d-flex flex-column justify-content-center align-items-center'>
              <i className='bi bi-telephone'></i>
              <h3>Զանգահարեք մեզ</h3>
              <p>
                <a href='tel:+37494122477'>+374 94 122 477</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
